import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { getUser, Pack, PackGetter } from "../firebase/firestore";
import { UIPack } from "../pages/packs";
const DEBUG = true;
const debugLog = (...args) => DEBUG && console.log("usePacks: ", ...args);
export const usePacks = (userId: string, getPacks: PackGetter, chunkSize = 10) => {
  const [packs, setPacks] = useState<UIPack[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [packsLoading, setPacksLoading] = useState(false);
  const loadMore = useCallback(
    async (firstCall?: boolean) => {
      if (!userId) return;
      let lastTrack = packs[packs.length - 1] || null;
      // @ts-ignore
      debugLog(`getting tracks for user ${userId} after ${lastTrack?.id} (packs.length = ${packs.length})`);

      setPacksLoading(true);
      let docs = await getPacks(
        { id: userId, uid: userId },
        chunkSize + 1,
        firstCall ? undefined : lastTrack?.snapshot
      );
      if (docs.length <= chunkSize) setHasMore(false);

      const processedPacks = (await Promise.all(docs.slice(0, chunkSize).map(docToPack))).filter((p) => !!p);

      // @ts-ignore
      setPacks(firstCall ? processedPacks : packs.concat(processedPacks));

      setPacksLoading(false);
    },
    [packs, userId]
  );

  useEffect(() => {
    debugLog("usePacks: userId changed", userId);
    setPacks([]);
    // setTimeout(() => loadMore(true), 1000);
    loadMore(true);
  }, [userId]);
  return { packs, loadMore, hasMore, setPacks, packsLoading };
};
export async function docToPack(_packDoc: DocumentSnapshot<DocumentData> | Pack): Promise<Pack> {
  // is an object already
  // @ts-ignore
  if (!_packDoc.data) return _packDoc;

  //@ts-ignore
  let packDoc: DocumentSnapshot<DocumentData> = _packDoc;
  let creatorRef = packDoc.ref.parent.parent;

  //@ts-ignore
  let pack: Pack = packDoc.data();
  if (!pack) return null;
  pack["creator"] = await getUser(creatorRef.id);
  pack["ref"] = packDoc.ref;
  pack["id"] = packDoc.id;
  pack["path"] = packDoc.ref.path;
  pack["snapshot"] = packDoc;
  return pack;
}
