import { useMemo } from "react";

export function useNicknameSubdomain() {
    const res = useMemo(() => {

        if (typeof window === "undefined") return { nickname: null, newPath: null, cleanPath: null }
        const { host, pathname: path } = window?.location;
        let isDev = host.includes('localhost');
        let splitHost = host.split('.');


        if ((!isDev && splitHost.length === 3) || (isDev && splitHost.length === 2)) {
            let nickname = splitHost.shift();
            if (nickname === 'www') {
                return null;
            }
            let newPath = `${window.location.protocol}//${splitHost.join('.')}/@${nickname}${path}`
            let cleanPath = `${window.location.protocol}//${splitHost.join('.')}${path}`

            return { nickname, newPath, cleanPath }
        }
        return { nickname: null, newPath: null, cleanPath: null }
    }, [typeof window !== "undefined" && window?.location])

    return res || { nickname: null, newPath: null, cleanPath: null }

}