import Box from "../components/layout/Box";
import * as Yup from 'yup';
import { LightboxPage } from "../components/layout/LightboxPage";
import { Form, Formik } from "formik";
import { useAuth } from "./useAuth";
import slugify from "slugify"
import { FormikInput } from "../components/LoginBox";
import Button from "../components/inputs/Button";
import { completeProfile, getUserIdByNickname } from "../firebase/firestore";

Yup.addMethod(Yup.string, "uniqueNickname", function (errorMessage) {
    return this.test(`test-card-type`, errorMessage, async function (value) {
        const { path, createError } = this;

        if (!value) return false
        return (
            (await getUserIdByNickname(value)) == null ||
            createError({ path, message: errorMessage })
        );
    });
});
const validationSchema = Yup.object().shape({
    //@ts-ignore
    nickname: Yup.string().max(25, "Too long").required('Required').matches(/^[a-zA-Z0-9_.-]*$/gi, 'letters, numbers, -, and _ only').uniqueNickname("taken already"),
});

const generateNickname = user => {
    if (user.firstName && user.lastName) return slugify(user?.firstName + ' ' + user?.lastName)
    if (user.email) return slugify(user.email.split('@')[0])
}

export function NicknameInput({ className="", ...rest }) {

    const { user } = useAuth()
    const handleComplete = async ({ nickname }) => {
        await completeProfile(user.uid, nickname.toLocaleLowerCase(), user.nickname)
    }
    return <Formik
        initialValues={{ nickname: generateNickname(user) }}
        validationSchema={validationSchema}
        onSubmit={handleComplete}
    >
        {({ isSubmitting, isValid, values }) => {
            return (
                <Form className={className + ` w-full flex flex-col`}>
                    <FormikInput className="w-full" type="text" name="nickname" subtitle={`Your links will start with ${values?.nickname?.toLocaleLowerCase() || 'your-nickname'}.beatpacks.app if you get the member plan`} placeholder="Your nickname" />

                    <div className='flex -mt-4 justify-end items-center'>
                        <Button primary className="ml-4" type="submit" disabled={isSubmitting || !isValid}>
                            Done
                        </Button>
                    </div>
                </Form>
            );
        }}
    </Formik>
}
export function CompleteSignup({ ...rest }) {
    return <LightboxPage>
        <Box title={"How should we call you?"} subtitle={""} className={"w-full max-w-lg"} maxWidth={550}>
            <NicknameInput />
        </Box>
    </LightboxPage>
}
