import { Subtitle } from "../misc/Typography";
export default function Box({ title, className, maxWidth, subtitle, children, ...rest }) {
    return <div style={{
        outline: '1px solid #414141',
        border: '1px solid #777777',
        maxWidth: maxWidth || undefined
    }} className={`${className} border-1 m-6 text-white  rounded-md backdrop-blur-lg shadow-lg  focus-within:outline-2  z-10 py-6 px-8`}>
        <div className="mb-8 text-center">
            {title && <Subtitle >{title}</Subtitle>}
            {subtitle && <div className="text-light-gray text-sm" >{subtitle}</div>}
        </div>

        {children}
    </div>;
}
