import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { Logo } from "../misc/Logo";
import { useAuth } from "../../hooks/useAuth";
import Link from "next/link";
import { FaShareAlt, FaUserAlt } from "react-icons/fa";
import { ActionRow } from "../misc/Actions";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";
import { useScreenSize } from "../../styles/media-queries";
import { IoMdAdd, IoMdClose, IoMdMusicalNote } from "react-icons/io";
import { GoThreeBars } from "react-icons/go";
import { getUserCollectives, getUserCollectivesQuery } from "../../firebase/firestore/collectives";
import UserWidget from "../user/UserWidget";

import { useCollectionData } from "react-firebase-hooks/firestore";
import { User } from "../../firebase/firestore";
import { FiPackage } from "react-icons/fi";
import { AiOutlineShareAlt } from "react-icons/ai";
import { MdAddCircleOutline, MdOutlineEmail } from "react-icons/md";
import { HiUsers } from "react-icons/hi";
import { RiShareFill } from "react-icons/ri";

const _NavbarItem = styled.div`
  font-family: Poppins;
  padding: 8px 12px;

  cursor: pointer;
  font-size: 1.1rem;
  transition: background 0.2s ease;
  :hover {
    background: rgba(255, 255, 255, 0.12);
  }
`;

function SmallUserMenu({ user, ...rest }) {
  const [open, setOpen] = useState(false);
  return (
    <div {...rest}>
      <div className={` relative opacity-50 hover:opacity-100 ${open ? "opacity-100" : ""} transition-opacity `}>
        <FaUserAlt onClick={() => setOpen(!open)} className={`cursor-pointer`} />
        {open && (
          <div
            className={`w-auto z-40 mt-2 absolute bg-bgactive__ left-1/2 top-full -translate-x-1/2 rounded-md overflow-hidden`}
          >
            <ActionRow action={{ cb: async () => signOut(auth), title: "Log out" }}></ActionRow>
          </div>
        )}
      </div>
    </div>
  );
}
const NavbarItem = ({ path, children, ...rest }) => (
  <Link href={path} className="w-full h-full">
    <_NavbarItem {...rest}>
      <div>{children}</div>
    </_NavbarItem>
  </Link>
);
export default function Navbar({ className, ...rest }) {
  const { user } = useAuth();
  const { phone } = useScreenSize();
  const [open, setOpen] = useState(false);
  const usercard = <UserWidget user={user} className={`${phone && (open ? "" : "hidden")} m-4 mb-6`} card={!phone} />;

  const userCollectives = useMemo(() => getUserCollectives(user.uid), [user]);

  const [values, loading, error, snapshot] = useCollectionData<User>(getUserCollectivesQuery(user.uid));

  return (
    <div {...rest} className={`${className} ${phone && "fixed top-0 left-0"} flex flex-col relative bg-bgactive`}>
      <div className="flex items-center justify-between p-4 pt-6">
        <div>
          <Logo className="inline text-2xl m-2" />
          {/* <span className="opacity-70 ml-1 ">beta</span> */}
        </div>
        <div className="flex items-center">
          {/* <SmallUserMenu user={user} className={phone && `mr-4 ${open ? 'opacity-1' : 'opacity-0'}`} /> */}
          {phone && (
            <div className="" onClick={() => setOpen(!open)}>
              {open ? <IoMdClose className="w-7 h-7" /> : <GoThreeBars className="w-7 h-7" />}
            </div>
          )}
        </div>
      </div>
      <div
        className={`grow transition-all ${phone ? "z-20 w-full bg-bgactive top-15 left-0" : "mt-10"} ${
          phone && (open ? "" : "h-0")
        } overflow-hidden`}
      >
        <NavbarItem path={"/create"} onClick={() => setOpen(false)}>
          {" "}
          <div className="flex items-center">
            <MdAddCircleOutline className="mr-4" />
            Create pack
          </div>
        </NavbarItem>
        <NavbarItem path={"/packs"} onClick={() => setOpen(false)}>
          <div className="items-center flex">
            <FiPackage className="mr-4" /> My packs
          </div>
        </NavbarItem>
        <NavbarItem path={"/tracks"} onClick={() => setOpen(false)}>
          <div className="items-center flex">
            <IoMdMusicalNote className="mr-4" />
            My tracks
          </div>
        </NavbarItem>
        <NavbarItem path={"/shared"} onClick={() => setOpen(false)}>
          <div className="items-center flex">
            <AiOutlineShareAlt className="mr-4" />
            Shared with me
          </div>
        </NavbarItem>
        <NavbarItem path={"/contacts"} onClick={() => setOpen(false)}>
          <div className="items-center flex">
            <MdOutlineEmail className="mr-4" />
            Email lists
          </div>
        </NavbarItem>

        {values?.length > 0 && (
          <div>
            <div className="flex items-center my-2 px-3">
              <div className="h-px w-4 bg-gray-500 mr-2" />

              <div className="  text-sm text-gray-500">Collectives</div>
              <div className="h-px grow mx-2 bg-gray-500" />
            </div>

            {values.map((collective) => (
              <NavbarItem path={`/collective/${collective.id}`} onClick={() => setOpen(false)}>
                <div className="items-center flex">
                  <HiUsers className="mr-4" />
                  {collective.collectiveName}
                </div>
              </NavbarItem>
            ))}
          </div>
        )}
      </div>
      {usercard}
    </div>
  );
}
