import Link from 'next/link'
import styled from 'styled-components'

const LogoTexts = styled.div`
  display: block;
  font-family: Sen;
  color: white;
  // font-size: 2rem;
  cursor:pointer;
`

export const Logo = ({ ...props }) => <Link href="/">
  <LogoTexts {...props}>beatpacks</LogoTexts>
  </Link>