import { signInWithEmailAndPassword, createUserWithEmailAndPassword, AuthCredential, UserCredential } from "firebase/auth";
import { auth } from "./index"

// todo: https://github.com/gladly-team/next-firebase-auth for server rendering
export async function loginEmailAndPassword(email: string, password: string): Promise<any> {
    try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        // Signed in 
        return userCredential.user;
    } catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        // todo: make readable for UI

        switch (errorCode) {
            case "auth/user-not-found":
            case "auth/wrong-password":
                throw "Wrong credentials"

        }
        throw errorMessage;
    }

}

export async function signUpWithEmailAndPassword(email: string, password: string): Promise<UserCredential> {
    try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        // Signed in 
        return userCredential;
    } catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        
        throw errorMessage;
    }

}