import React, { useCallback, useMemo, useRef, useState } from 'react'
import { BsThreeDots } from "react-icons/bs"
import { useOutsideClick } from '../../hooks/useOutsideClick';

export type Action = {
  cb: () => Promise<any>;
  title: string;
  subtitle?: string;
};
export type ActionsType = Action[];

type ActionsProps = {
  actions: ActionsType;

  [x: string]: any;
}

export function ActionRow({ action, ...rest }: { action: Action }) {
  const [loading, setLoading] = useState(false)
  const exec = useCallback(async e => {
    e.preventDefault()
    setLoading(true)
    await action.cb();
    setLoading(false)
  }, [action])

  return <div onClick={exec} className={`${loading ? 'text-loading' : ''} z-30 w-24 text-sm px-2 py-1 hover:bg-bgactive__ bg-bgactive_ text-light-gray cursor-pointer`}>
    {action.title}
  </div>
}

export default function Actions({ actions, className, ...rest }: ActionsProps) {
  const [open, setOpen] = useState(false)
  const conRef = useRef(null)
  useOutsideClick(conRef, () => {
    if (open) {
      setOpen(false)
    }
  });
  return (
    <div ref={conRef} className={`${className}`} {...rest}>
      <BsThreeDots onClick={e => { e.preventDefault(); setOpen(!open) }} size={20} className="text-light-gray hover:text-white transition-all" />
      {open && <div className='absolute left-1/2 top-full -translate-x-1/2 mt-1 shadow-md rounded-md overflow-hidden'>
        {actions.map((action, idx) => <ActionRow key={`action-${idx}`} action={action} />)}
      </div>}
    </div>
  )
}
