import { FacebookAuthProvider, GoogleAuthProvider, linkWithCredential, linkWithPopup, signInWithEmailAndPassword, signInWithPopup, TwitterAuthProvider } from 'firebase/auth';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import Link from 'next/link';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSignInWithFacebook, useSignInWithGoogle, useSignInWithTwitter } from "react-firebase-hooks/auth";
import { AiOutlineGoogle, AiOutlineTwitter } from 'react-icons/ai';
import { ImFacebook } from 'react-icons/im';
import * as Yup from 'yup';
import { loginEmailAndPassword, signUpWithEmailAndPassword } from "../firebase/auth";
import { getUserIdByNickname } from "../firebase/firestore";
import { auth } from "../firebase/index";
import { useAuth } from '../hooks/useAuth';
import Button from "./inputs/Button";
import Input from './inputs/Input';
import Box from "./layout/Box";
import ClickText from './misc/ClickText';

const googleProvider = new GoogleAuthProvider()
const twitterProvider = new TwitterAuthProvider()
const fbProvider = new FacebookAuthProvider()
const loginSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string().required('Required'),
});

export const SmallErrorText = ({ className, ...rest }) => <div className={`${className} text-red-500 text-xs p-1`} {...rest} />
export const FormikInput = ({ name, subtitle, placeholder, type, ...rest }) =>
    <label>
        <Field name={name} type={type} {...rest}>
            {({
                field, // { name, value, onChange, onBlur }
                form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                meta,
            }) => (
                <div className='mb-8'>
                    <div className='group relative'>
                        <Input className=' ' type={type} transparent  {...field} />
                        <span style={{ color: '#9f9f9f' }} className={`transition-all group-focus-within:-top-2.5 ${field.value && "text-xs"} px-1 group-focus-within:left-0 rounded-md group-focus-within:text-xs absolute  ${field.value ? "-top-2.5  left-0  " : "top-1/2 left-2"} -translate-y-1/2`}>{placeholder}</span>
                        <SmallErrorText className={"absolute right-6 top-1/2 -translate-y-1/2 float-right "}><ErrorMessage name={name} component="div" /></SmallErrorText>
                    </div>
                    {subtitle && <div className='text-xs m-1 text-bgactive__'> {subtitle}</div>}</div>
            )}
        </Field>
    </label>

function LoginForm({ handleLogin, handleSignupIntent }) {
    const { user } = useAuth()
    const [signInWithTwitter, twitterUser, twitterLoading, twitterError] = useSignInWithTwitter(auth);
    const [signInWithGoogle, googleUser, googleLoading, googleError] = useSignInWithGoogle(auth,);
    const [signInWithFacebook, fbUser, fbLoading, fbError] = useSignInWithFacebook(auth);
    const [localError, setLocalError] = useState(null)
    const [localLoading, setLocalLoading] = useState(false)

    const globalError = useMemo(() => localError || twitterError || googleError || fbError, [localError, twitterError, googleError, fbError])
    const globalLoading = useMemo(() => localLoading || twitterLoading || googleLoading || fbLoading, [localLoading, twitterLoading, googleLoading, fbLoading])

    const [anonUser, setAnonUser] = useState(user)
    useEffect(() => user?.isAnonymous && setAnonUser(user), [user])

    // todo: transfer anon user info over to new logged in if credential already in use
    const anonSignUp = provider => async () => {
        try {
            await linkWithPopup(auth.currentUser, provider)
        } catch (e) {
            await signInWithPopup(auth, provider)
        }
    }

    return <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={loginSchema}
        onSubmit={async (...args) => {
            try { await handleLogin(...args); } catch (e) { setLocalError(e) }
        }}
        className={`relative`}
    >

        {({ isSubmitting, isValid }) => {
            return (
                <Form className='w-full flex flex-col'>
                    {globalLoading && <div className='absolute top-0 z-20 left-0 w-full h-full bg-black rounded-md bg-opacity-75'></div>}
                    <FormikInput className="" type="email" name="email" placeholder="Email" />

                    <FormikInput type="password" name="password" placeholder="Password" />

                    <div className='flex -mt-4 justify-end items-center'>
                        {/* <SmallErrorText className={" mr-auto"}>{globalError}</SmallErrorText> */}
                        <Button type="button" secondary onClick={handleSignupIntent}>Sign up</Button>
                        <Button primary={!!!globalError} fail={!!globalError} className="ml-4" type="submit" disabled={isSubmitting || !isValid}>
                            Sign in
                        </Button>
                    </div>

                    <div className='flex justify-center items-center mt-8 '>
                        <div style={{ background: '#9f9f9f' }} className=' opacity-20 h-px grow '></div>
                        <div style={{ color: '#9f9f9f' }} className='mx-2 text-sm text-center'>or sign in with</div>

                        <div style={{ background: '#9f9f9f' }} className='opacity-20 h-px grow '></div>
                    </div>
                    <div className='flex mt-4'>
                        <Button type="button" className="mr-1 grow" onClick={anonUser ? anonSignUp(googleProvider) : () => signInWithGoogle()} secondaryStatic><AiOutlineGoogle color='white' size={22} /></Button>
                        <Button type="button" className="mx-1 grow" onClick={anonUser ? anonSignUp(twitterProvider) : () => signInWithTwitter()} secondaryStatic><AiOutlineTwitter color='white' size={22} /></Button>
                        <Button type="button" className="ml-1 grow" onClick={anonUser ? anonSignUp(fbProvider) : () => signInWithFacebook()} secondaryStatic><ImFacebook color='white' size={17} /></Button>
                    </div>
                    <div className='opacity-70 text-xxs text-center mt-4'>Usage implies agreement with the <Link href={"/terms"}><ClickText>terms of service</ClickText></Link> and <Link href={"/privacy"}><ClickText>privacy policy</ClickText></Link></div>
                </Form>
            );
        }}
    </Formik>
}

Yup.addMethod(Yup.string, "uniqueNickname", function (errorMessage) {
    return this.test(`test-card-type`, errorMessage, async function (value) {
        const { path, createError } = this;

        if (!value) return false
        return (
            (await getUserIdByNickname(value)) == null ||
            createError({ path, message: errorMessage })
        );
    });
});

// todo: add nickname, producer/artist, etc.
const signupSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string()
        .min(6, "Too short")
        .max(50, "Too long")
        .required("Required"),
    // nickname: Yup.string().max(25, "Too long").required('Required').matches(/^[a-zA-Z0-9_.-]*$/gi, 'letters, numbers, -, and _ only').uniqueNickname("taken already"),
});

function SignupForm({ handleSignup, handleLoginIntent }) {
    const handleSubmit = useCallback(({ email, password, nickname }) => {
        handleSignup({ email, password })
    }, [])
    return <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={signupSchema}
        onSubmit={handleSignup}
    >
        {({ isSubmitting, values }) => {
            return (
                <Form className=' w-full flex flex-col'>

                    <FormikInput className="" type="email" name="email" placeholder="Email" />

                    <FormikInput type="password" subtitle={"min. 6 characters"} name="password" placeholder="Password" />

                    {/* <FormikInput className="w-full" type="text" name="nickname" subtitle={`Your links will start with ${values.nickname || 'your-nickname'}.beatpacks.app`} placeholder="Your nickname" /> */}
                    <div className='flex justify-end items-center'>
                        <Button type="button" secondary onClick={handleLoginIntent}>Have an account?</Button>
                        <Button primary className="ml-4" type="submit" disabled={isSubmitting}>
                            Sign up
                        </Button>
                    </div>
                </Form>
            );
        }}
    </Formik>
}
export default function LoginBox({ title }) {
    const [singingUp, setSigningUp] = useState(false)
    const { user } = useAuth()
    let handleLogin = async (values, { setSubmitting }) => {
        setSubmitting(true)
        let creds = await loginEmailAndPassword(values.email, values.password)
        if (user?.isAnonymous) {

            await linkWithCredential(auth.currentUser, creds)
        }
        setSubmitting(false)
    }

    let handleSignup = async (values, { setSubmitting }) => {
        let creds = await signUpWithEmailAndPassword(values.email, values.password)
        signInWithEmailAndPassword(auth, values.email, values.password)
        if (user?.isAnonymous) {
            await linkWithCredential(auth.currentUser, creds)
        }
    };
    return <Box className="flex flex-col w-5/6 md:w-full max-w-md items-center" title={title || (singingUp ? 'Create account' : 'Sign in')} >
        {singingUp ? <SignupForm handleSignup={handleSignup} handleLoginIntent={() => setSigningUp(false)} /> : <LoginForm handleLogin={handleLogin} handleSignupIntent={() => setSigningUp(true)} />}
    </Box>
}