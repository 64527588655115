import LoginBox from "../components/LoginBox"
import { removeLayout } from "../utils/pageHelpers"
import { useRouter } from "next/router"
import { LightboxPage } from "../components/layout/LightboxPage"
import { useAuth } from "../hooks/useAuth"
import { useEffect } from "react"

export default function Login({title}) {
    const {user} = useAuth()
    const router = useRouter()
    useEffect(() => {
        if (user && !user.isAnonymous) {
            router.push('/packs')
        }
    }, [user])
    return (
        <LightboxPage>
            <LoginBox title={title} />
        </LightboxPage>
    )
}

removeLayout(Login)