import { saveAs } from "file-saver";
import { logEvent } from "firebase/analytics";
import {
  addDoc,
  arrayUnion,
  collection,
  collectionGroup,
  deleteDoc,
  doc,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  FirestoreDataConverter,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  QueryDocumentSnapshot,
  runTransaction,
  serverTimestamp,
  setDoc,
  SnapshotOptions,
  startAfter,
  Timestamp,
  updateDoc,
  where,
  WithFieldValue,
} from "firebase/firestore";
import { deleteObject, getBlob, getDownloadURL, ref } from "firebase/storage";
import slugify from "slugify";
import { Viewer } from "../../hooks/useObjectViews";
import { docToPack } from "../../hooks/usePacks";
import { FileUpload, FirestoreTrack, Key, Stem, UIStem } from "../../hooks/useUpload";
import { mergeSorted } from "../../utils/arrays";
import { randomString } from "../../utils/string";
import { analytics, db, storage } from "../index";
import { ContactList } from "./contacts";
import { userConverter } from "./user";

var JSZip = require("jszip");

const DEBUG = true;
const debugLog = (...args) => DEBUG && console.log(...args);
export type Source = "custom" | "audio" | "file";
export type Bpm = number;

type FSDate = Date | Timestamp | any;

export type TagType = string;
export interface Track {
  url?: string;
  title: string;
  uploadDate?: FSDate;
  analyze?: boolean;

  // firestore refs
  id?: string;
  path?: string;

  bpm?: { source: Source; value: Bpm };
  key?: { source: Source; value: Key };
  file?: { bpm?: Bpm; key?: Key };
  audio?: { bpm?: Bpm; key?: Key };
  tags: TagType[];
  waveform?: number[];
  analyze_status?: "queued" | "done" | "running";
  creator?: User;
  duration?: number;

  stems?: Stem[];
}

export type WeekliesObject = {
  sendout_day?: number;
  recipients: string[];
  next_pack: string;
  recycle: boolean;
};

export type PrivateLink = { source: "custom" | "title"; value: string };
export type EmailInfo = {
  date: Timestamp;
  email_id?: string;

  // used for receipts of emails to mail lists
  email_ids?: string[];
};
export type EmailsSent = {
  [idOrEmailOrListPath: string]: EmailInfo;
};
export type Pack = {
  title?: string;
  date_created?: Date;
  date_published?: Date;
  date_modified?: Timestamp;
  published?: boolean;

  tracks?: DocumentReference[];
  id?: string;
  ref?: DocumentReference;

  // set either dynamically by fetching owner (from document path), or is statically set on pack object
  // if set statically, the value is frozen in time. So don't use any dynamic fields (only id, nickname, isCollective, ...)
  creator?: User;

  weekly?: boolean;
  next_weekly?: boolean;
  emails_sent?: EmailsSent;

  shared_with?: (string | DocumentReference<ContactList>)[];
  shared_with_favs?: boolean;
  private_link?: PrivateLink;
  publicLink?: string; // used locally; if set, this pack was shared with you by public link and should be accessed by it

  isCollective?: boolean;
  submissionsOpen?: boolean;
  submissionsPerPerson?: number;
};

export type User = {
  nickname?: string;
  id: string;
  uid: string;
  email?: string;
  plan?: Plan;
  ref: DocumentReference;

  isCollective?: boolean;
  members?: string[];
  membersUsers?: User[];
  creator?: string;
  creatorUser?: User;
  admins?: string[];
  adminsUsers?: User[];
  collectiveName?: string;
};

export type Collective = {
  admins: string[];
  members: string[];
  creator: string;
  name: string;
};

export type Plan = "free" | "member" | "platinum";

export const MAX_UPLOADS: { [plan: string]: number } = {
  free: 500,
  member: 10000,
  platinum: 100000,
};

// @ts-ignore
// warning: we're interpreting firestore data outside of firestore.ts module... bad for dependency injection
export const docToUiTrack = (doc: DocumentSnapshot, creator: User): FirestoreTrack => ({
  track: { ...doc.data(), creator, id: doc.id, path: doc.ref.path },
  ref: doc.ref,
  snapshot: doc,
});

export const keyToString = (key: Key) => {
  if (!key) return "";
  try {
    return `${key.key}${key.mode === "minor" ? "m" : ""}`;
  } catch (e) {
    throw `couldn't turn ${JSON.stringify(key)} to string`;
  }
};

export type UITrack = (FileUpload | FirestoreTrack) & {
  selected?: boolean;
  justUploaded?: boolean;
};

export async function addTrack(ownerId: string, trackId: string, track: Track) {
  delete track.id;
  delete track.path;
  debugLog("adding track", track);
  if (!track.url) delete track.url;
  let trackRef = doc(db, `users/${ownerId}/tracks/${trackId}`);
  await setDoc(trackRef, track);
  return trackRef;
}

export function authUserToFsUser(user): User {
  return {
    id: user.uid,
    uid: user.uid,
    ref: doc(db, `users/${user.uid}`),
    nickname: user.nickname,
  };
}
export async function completeProfile(uid: string, nickname: string, previousNickname?: string) {
  nickname = nickname.toLocaleLowerCase();
  try {
    await runTransaction(db, async (transaction) => {
      const previousNicknameRef = doc(db, `user_nicknames/${previousNickname}`);
      if (previousNickname && (await transaction.get(previousNicknameRef)).exists) {
        await transaction.delete(previousNicknameRef);
      }
      await transaction.set(doc(db, `users/${uid}`), { nickname });
      await transaction.set(doc(db, `user_nicknames/${nickname}`), { uid });
    });
    console.log("Transaction successfully committed!");
  } catch (e) {
    console.log("Transaction failed: ", e);
  }
}

export async function deletePackPublicLinks(creatorId: string, packId: string) {
  let publicLinks = await getDocs(
    query(collection(db, `users/${creatorId}/pack_links`), where("pack_id", "==", packId))
  );
  publicLinks.forEach((publicLink) => deleteDoc(publicLink.ref));
}
export async function deletePack(pack: Pack) {
  await deletePackPublicLinks(pack.creator.id, pack.id);

  deleteDoc(pack.ref);
}

export const UserUndefinedErr = new Error(`passed undefined user`);
async function recordView(
  docRef: DocumentReference,
  viewer: User,
  interactionSubCollection: "downloads" | "views",
  additionalData?: any
): Promise<DocumentReference> {
  if (!viewer) throw UserUndefinedErr;

  const viewerObject: Viewer = { uid: viewer.id || viewer.uid };
  if (viewer.nickname) viewerObject.nickname = viewer.nickname;
  const viewRef = await addDoc(collection(db, `${docRef.path}/${interactionSubCollection}`), {
    viewer: viewerObject,
    date: serverTimestamp(),
    ...additionalData,
  });

  return viewRef;
}

export const recordPackView = (packRef: DocumentReference, viewer: User) => recordView(packRef, viewer, "views");
export const recordTrackDownload = (trackRef: DocumentReference, viewer: User, pack?: Pack) => {
  console.log(pack);
  return recordView(trackRef, viewer, "downloads", pack ? { packId: pack.id } : {});
};

export async function getMyTracks(
  userId: string,
  lim: number,
  lastShownSnapshot?: DocumentSnapshot,
  excludeIds?: string[]
): Promise<DocumentSnapshot[]> {
  const tracksRef = collection(db, `users/${userId}/tracks`);

  if (excludeIds?.length) lim += excludeIds.length;

  let q = lastShownSnapshot
    ? query(tracksRef, orderBy("uploadDate", "desc"), limit(lim), startAfter(lastShownSnapshot))
    : query(tracksRef, orderBy("uploadDate", "desc"), limit(lim));
  const snapshot = await getDocs(q);

  if (!excludeIds?.length) return snapshot.docs;
  return snapshot.docs.filter((d) => !excludeIds.find((exclId) => exclId === d.id));
}

export const packConverter: FirestoreDataConverter<Pack> = {
  toFirestore(pack: WithFieldValue<Pack>): DocumentData {
    return pack;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): Pack {
    const data = snapshot.data(options);
    return {
      ...data,
      title: data.title,
      date_created: data.date_created,
      published: data.published,
      shared_with_favs: data.shared_with_favs,
      id: snapshot.id,
      ref: snapshot.ref,
    };
  },
};

export const getMyPacks: PackGetter = async (
  { id: userId },
  lim: number,
  lastShownSnapshot?: DocumentSnapshot,
  excludeIds?: string[]
): Promise<DocumentSnapshot[]> => {
  const packsRef = collection(db, `users/${userId}/packs`).withConverter(packConverter);

  if (excludeIds?.length) lim += excludeIds.length;

  let q = lastShownSnapshot
    ? query(packsRef, orderBy("date_created", "desc"), limit(lim), startAfter(lastShownSnapshot)).withConverter(
        packConverter
      )
    : query(packsRef, orderBy("date_created", "desc"), limit(lim)).withConverter(packConverter);
  const snapshot = await getDocs(q);

  if (!excludeIds?.length) return snapshot.docs;
  return snapshot.docs.filter((d) => !excludeIds.find((exclId) => exclId === d.id));
};

export type PackGetter = (
  user: User,
  lim: number,
  lastShownSnapshot?: DocumentSnapshot,
  excludeIds?: string[]
) => Promise<DocumentSnapshot[]>;
export const getPacksSharedWithMe: PackGetter = async (
  user: User,
  lim: number,
  lastShownSnapshot?: DocumentSnapshot,
  excludeIds?: string[]
): Promise<DocumentSnapshot[]> => {
  const packsRef = collectionGroup(db, `packs`);

  if (excludeIds?.length) lim += excludeIds.length;

  let q = lastShownSnapshot
    ? query(
        packsRef,
        where("shared_with", "array-contains-any", [user.uid]),
        orderBy("date_created", "desc"),
        limit(lim),
        startAfter(lastShownSnapshot)
      )
    : query(
        packsRef,
        where("shared_with", "array-contains-any", [user.uid]),
        orderBy("date_created", "desc"),
        limit(lim)
      );
  const snapshot = await getDocs(q);

  let publicSharedQuery = lastShownSnapshot
    ? query(
        collection(db, `users/${user.uid}/public_shared_packs`),
        orderBy("date_accessed", "desc"),
        limit(lim),
        startAfter(lastShownSnapshot)
      )
    : query(collection(db, `users/${user.uid}/public_shared_packs`), orderBy("date_accessed", "desc"), limit(lim));
  const publicSharedSnapshot = await getDocs(publicSharedQuery);

  // get the packs shared w/ me by public link, save the public link in Pack object to access it again
  const publicSharedDocs = (
    await Promise.all(
      publicSharedSnapshot.docs.map(async (d) => {
        const packDoc = await getDoc(d.data().pack);
        console.log(packDoc);
        let pack = await docToPack(packDoc);
        if (!pack) return null;
        pack.publicLink = d.data().public_link;
        return pack;
      })
    )
  ).filter((d) => d);

  const docs = mergeSorted(
    snapshot.docs,
    publicSharedDocs,
    (ldoc, rdoc) => ldoc.data().date_modified < rdoc.date_accessed
  );

  console.log(docs);
  if (!excludeIds?.length) return docs;
  return docs.filter((d) => !excludeIds.find((exclId) => exclId === d.id));
};

export const getCollectivePacksCollection = (collectiveId: string) =>
  collection(db, `collectives/${collectiveId}/packs`);

export const getCollectiveRef = (userId: string) =>
  doc(db, `users/${userId}`)?.withConverter(userConverter) as DocumentReference<User>;
export async function updateCollectiveObject(
  collectiveRef: DocumentReference,
  data: Partial<Collective>
): Promise<any> {
  return updateDoc(collectiveRef, data);
}

export async function getTrack(trackRef: DocumentReference, creator: User): Promise<UITrack> {
  return docToUiTrack(await getDoc(trackRef), creator);
}

export function getUserRef(userId: string) {
  return doc(db, `users/${userId}`);
}
export async function deleteTrackDb(trackPath: string) {
  return deleteDoc(doc(db, trackPath));
}
export async function deleteTrackStorage(track: Track, storagePath: string) {
  return await Promise.all([
    ...track.stems.map((stem) => deleteObject(ref(storage, stem.storagePath))),
    deleteObject(ref(storage, storagePath)),
  ]);
}

export function updateTrack(trackPath: string, newTrack: any) {
  return updateDoc(doc(db, trackPath), newTrack);
}

export async function getTrackAudioURL(track: Track): Promise<string> {
  const trackRef = ref(storage, track.url);
  return await getDownloadURL(trackRef);
}

export async function getStemAudioUrl(stem: Stem): Promise<string> {
  const trackRef = ref(storage, stem.storagePath);
  return await getDownloadURL(trackRef);
}

export async function getUserIdByNickname(nickname: string): Promise<string> {
  const snapshot = await getDoc(doc(db, `user_nicknames/${nickname}`));

  if (!snapshot.exists || !snapshot.data()) return null;

  //@ts-ignore
  return snapshot.data().uid;
}

export async function getUser(uid: string): Promise<User> {
  const snapshot = await getDoc(doc(db, `users/${uid}`));
  if (!snapshot.exists || !snapshot.data()) return null;

  //@ts-ignore
  return { ...snapshot.data(), id: uid, ref: snapshot.ref };
}

export const getTrackBlob = async (track: FirestoreTrack) => {
  return await getBlob(ref(storage, track.track.url));
};
export const downloadTrack = async (
  track: FirestoreTrack,
  trackCreator: User,
  viewer: User,
  pack?: Pack,
  packCreator?: User
) => {
  // todo: create a zip file if track has stems
  const hasStems = track?.track?.stems.length;

  if (hasStems) {
    var zip = new JSZip();
    await Promise.all([
      ...track.track.stems.map(async (stem) =>
        zip.file(
          generateFileName(
            trackCreator.nickname,
            track,
            `${track.track.title} ${stem.name}`,
            packCreator?.collectiveName
          ),
          await getBlob(ref(storage, stem.storagePath))
        )
      ),
      zip.file(generateFileName(trackCreator.nickname, track), await getTrackBlob(track)),
    ]);

    let content = await zip.generateAsync({ type: "blob" });
    await saveAs(content, generateFileName(trackCreator.nickname, track, null, "zip"));
  } else {
    let blob = await getTrackBlob(track);
    let localFname = generateFileName(trackCreator.nickname, track, null, null, packCreator?.collectiveName);
    await saveAs(blob, localFname);
  }

  if (trackCreator.uid !== viewer.uid) recordTrackDownload(track.ref, viewer, pack);
};
export const downloadStem = async (track: FirestoreTrack, stem: Stem, creator: User) => {
  let blob = await getBlob(ref(storage, stem.storagePath));
  let localFname = generateFileName(creator.nickname, track, `${track.track.title} ${stem.name}`);
  return saveAs(blob, localFname);
};

// todo: zip in firebase function, download zipped
export async function downloadPack(pack: Pack, tracks: UITrack[]) {
  //@ts-ignore
  const fsTracks: FirestoreTrack[] = tracks.filter((track) => track?.ref);
  var zip = new JSZip();
  await Promise.all(
    fsTracks.map(async (track) =>
      zip.file(
        generateFileName(
          track?.track?.creator?.nickname || pack?.creator?.nickname,
          track,
          null,
          null,
          track?.track?.creator?.nickname && pack?.creator?.collectiveName
        ),
        await getTrackBlob(track)
      )
    )
  );
  let content = await zip.generateAsync({ type: "blob" });
  await saveAs(content, generatePackFileName(pack));
}
export async function getPackPublicLink(uid: string, packId: string): Promise<string> {
  const q = await query(collection(db, `users/${uid}/pack_links`), where("pack_id", "==", packId), limit(1));

  const snapshot = await getDocs(q);
  if (snapshot.docs.length == 0) return null;

  //@ts-ignore
  return snapshot?.docs[0]?.id || null;
}

export async function getPackIdByPublicLink(uid: string, publicLink: string): Promise<string> {
  const snapshot = await getDoc(doc(db, `users/${uid}/pack_links/${publicLink}`));

  if (!snapshot.exists || !snapshot.data()) throw PackNotFoundErr;
  //@ts-ignore
  return snapshot.data().pack_id;
}

export const PackNotFoundErr = new Error(
  "This pack doesn't exist or wasn't shared with you... Did you get the link right?"
);
export const PackNotSharedErr = new Error("This pack wasn't shared with you. Ask the owner for access and try again.");
export async function getPackByPrivateLink(
  ownerId: string,
  visitorId: string,
  privateLink: string,
  myPack: boolean
): Promise<[DocumentReference, DocumentSnapshot]> {
  try {
    let q;
    if (!myPack) {
      q = query(
        collection(db, `users/${ownerId}/packs`),
        where("shared_with", "array-contains-any", [visitorId]),
        where("private_link.value", "==", privateLink)
      );
    } else {
      q = query(collection(db, `users/${ownerId}/packs`), where("private_link.value", "==", privateLink));
    }
    let snapshot = await getDocs(q);

    console.log(snapshot);
    if (snapshot.docs.length > 0) {
      return [snapshot.docs[0].ref, snapshot.docs[0]];
    } else {
      throw PackNotFoundErr;
    }
  } catch (e) {
    console.error(e);

    throw PackNotSharedErr;
  }
}

export async function createPackLink(uid: string, packRef: DocumentReference): Promise<string> {
  let linkRef = await addDoc(collection(db, `/users/${uid}/pack_links`), {
    pack_id: packRef.id,
  });
  return linkRef.id;
}

export async function createPack(uid: string): Promise<DocumentReference> {
  const creator = await getUser(uid);
  // todo: wrap pack creation and link generation in transaction
  let docRef = await addDoc(collection(db, `/users/${uid}/packs`), {
    date_created: new Date(),
    tracks: [],
    private_link: {
      source: "auto",
      value: randomString(32),
    },
    creator,

    // submissionsOpen: true for collectives by default
    submissionsOpen: !!creator?.isCollective,
  });

  return docRef;
}

export async function updatePack(packRef: DocumentReference, data: any): Promise<any> {
  return updateDoc(packRef, { ...data, date_modified: new Date() });
}

function testRules() {
  getDoc(
    doc(
      db,
      "user/YGMbIcSvpLchLuy5Xp8NetNQTW23/tracks/sKR5Brx3Cl1vSD8yYRQ1xzPtE1NlqmWYQqF5utDBFisxKHRABAEDfbSKBZbgw5e4yeF0LHkayIQJ8lHCmTdUCw21j0bKooVJA0s3EVbzcXMev2dr0mHn1Vc43EqBwH0Tx"
    )
  ).then(console.log);
}

export async function setUserSettings(uid: string, newSettings: any) {
  return setDoc(doc(db, `users/${uid}/private/settings`), newSettings);
}

export async function savePublicSharedPack(uid: string, packRef: DocumentReference, publicLink: string) {
  setDoc(doc(db, `users/${uid}/public_shared_packs/${packRef.id}`), {
    date_accessed: new Date(),
    pack: packRef,
    public_link: publicLink,
  });
}
export async function generatePrivateLink(uid: string, packTitle: string): Promise<PrivateLink> {
  let link = slugify(packTitle);
  let snapshot = await getDocs(query(collection(db, `users/${uid}/packs`), where("private_link.value", "==", link)));

  if (snapshot.docs.length > 0) {
    link += `-${snapshot.docs.length}`;
  }

  return { source: "title", value: link };
}

type UserSub = {
  type: "free" | "standard" | "premium";
};

export async function getUserSubscription(uid: string): Promise<UserSub> {
  console.log(uid);
  return { type: "premium" };
}

// const actionCodeSettings = {
//   url: 'https://localhost:3000?email=sled9448@gmail.com',
//   handleCodeInApp: true
// };
// sendSignInLinkToEmail(auth, 'sled9448@gmail.com', actionCodeSettings)

// https://beatpacks.firebaseapp.com/__/auth/action?apiKey=AIzaSyCznZthOvw12dLIY2jqIuPMB-2VKI2KsMU&mode=signIn&oobCode=7961IxoMNpZaEs3r3JSmyaAlVatPa-oHsLPwGZHhtTQAAAF_fcDWNQ&continueUrl=https://localhost:3000?email%3Dvemaka1563@siberpay.com&lang=en"

export function generateFileName(
  nickname: string,
  track: FirestoreTrack,
  title?: string,
  ext?: string,
  collectiveName?: string
): string {
  const keyStr = track?.track?.key?.value ? keyToString(track.track.key.value) : "";
  const bpmStr = track?.track?.bpm?.value ? " " + track?.track?.bpm?.value + "bpm" : "";
  return `${title || track.track.title} [${keyStr}${bpmStr} @${nickname}${
    collectiveName ? ` (${collectiveName})` : ""
  }].${ext || track.track.url.split(".").pop()}`;
}

export function getLatestDraftPack(user: User) {
  // todo
}
export function generatePackFileName(pack: Pack) {
  return `${pack.title} [prod. @${pack.creator.nickname}].zip`;
}

export const writeEmail = async (email) => {
  logEvent(analytics, "write_email", { email });
  return setDoc(doc(collection(db, "emails")), { email, date: new Date() });
};

const getTomorrowDate = () => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow;
};
function getWeekNumber(d: Date): [number, number] {
  // Copy date so don't modify original
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  // Get first day of year
  var yearStart: Date = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  // Calculate full weeks to nearest Thursday
  // @ts-ignore
  var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  // Return array of year and week number
  return [d.getUTCFullYear(), weekNo];
}

export const generateWeeklyTitle = (releaseDate: Date) => {
  // @ts-ignore
  let [year, weekNumber] = getWeekNumber(releaseDate);

  return `week ${weekNumber} ${year}`;
};

// skips a week if same weekday as currently
export const daysLeftUntilNextWeekday = (weekDay: number) => (weekDay + 8 - new Date().getDay()) % 7 || 7;
export const getNextDateOfWeekday = (sendoutDay: number) => {
  var d = new Date();
  d.setDate(d.getDate() + daysLeftUntilNextWeekday(sendoutDay));
  return d;
};

export const setUpWeeklies = async (uid: string) => {
  const weekliesRef = getWeekliesRef(uid);

  let firstPackRef = await createPack(uid);

  const todayDayNumber = (new Date().getDay() + 6) % 7;
  const firstReleaseDate = getNextDateOfWeekday(todayDayNumber);
  const firstTitle = generateWeeklyTitle(firstReleaseDate);
  await updateDoc(firstPackRef, { weekly: true, next_weekly: true, title: firstTitle, date_modified: new Date() });

  await setDoc(weekliesRef, {
    sendout_day: todayDayNumber,
    recipients: [],
    next_pack: firstPackRef.id,
    recycle: true,
  });

  return weekliesRef;
};

export const stopWeeklies = async (uid: string) => {
  // delete next_weekly pack
  let weekliesRef = getWeekliesRef(uid);
  let weekliesObject = await getDoc(weekliesRef);
  let nextPackId = weekliesObject.data().next_pack;
  let mockPack = {
    ref: doc(db, `users/${uid}/packs/${nextPackId}`),
    creator: { id: uid, uid },
    id: nextPackId,
  };
  await deletePack(mockPack);

  // delete /weeklies
  await deleteDoc(weekliesRef);
};

function getWeekliesRef(uid: string) {
  return doc(db, `users/${uid}/private/weeklies`);
}
