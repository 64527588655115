export function mutateWhere<Type>(arr: Type[], mutate: (el: Type) => Type, predicate: (el: Type) => boolean) {
    return arr.map(el => predicate(el) ? mutate(el) : el)
}

export function mergeSorted(left, right, lessThan) {
    let arr = []
    // Break out of loop if any one of the array gets empty
    while (left.length && right.length) {
        // Pick the smaller among the smallest element of left and right sub arrays 
        if (lessThan(left[0], right[0])) {
            arr.push(left.shift())  
        } else {
            arr.push(right.shift()) 
        }
    }
    
    // Concatenating the leftover elements
    // (in case we didn't go through the entire left or right array)
    return [ ...arr, ...left, ...right ]
}

export const sum = arr => arr.reduce((partialSum, a) => partialSum + a, 0);