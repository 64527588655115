import "../utils/string"
import '../styles/globals.css'
import Layout from '../components/layout/Layout'
import { AuthUserProvider } from "../hooks/useAuth"
import { AudioPlayerProvider } from "react-use-audio-player"
import { TrackPlayerProvider } from "../hooks/useTrackPlayer"
import { useNicknameSubdomain } from "../hooks/useNicknameSubdomain"
import { useRouter } from "next/router"
import { useEffect } from "react"
import { getUser, getUserIdByNickname, getUserSubscription } from "../firebase/firestore"

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { hotjar } from 'react-hotjar';
import { analytics } from "../firebase"
import { logEvent } from "firebase/analytics"

Sentry.init({
  dsn: "https://311dcd6e58a649fbb651d179d6f93652@o524924.ingest.sentry.io/6291664",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});


function MyApp({ Component, pageProps }) {
  const getLayout = Component.getLayout || ((page) => <Layout>{page}</Layout>)

  const router = useRouter()
  const { nickname, cleanPath, newPath } = useNicknameSubdomain()

  useEffect(async () => {
    if (nickname) {
      let hostUserId = await getUserIdByNickname(nickname)

      let hostUser = await getUser(hostUserId)
      if (["member", "platinum"].includes(hostUser?.plan)) {
        router.push(newPath)
      } else {
        router.push(cleanPath)
      }
    }
  }, [nickname])

  useEffect(() => {
    hotjar.initialize('2928176', '6');
  }, [])

  // track route changes
  useEffect(() => {
    const handleRouteChange = url => {
      console.log(url)
      logEvent(analytics, 'page_view', {page_path: url})
      hotjar.stateChange(url);
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return <AuthUserProvider>
    <AudioPlayerProvider>
      <TrackPlayerProvider>
        {getLayout(<Component {...pageProps} />)}
      </TrackPlayerProvider>
    </AudioPlayerProvider>
  </AuthUserProvider>
}

export default MyApp
