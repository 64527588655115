import Image from 'next/image';
import {Logo} from "../misc/Logo"
export function LightboxPage({ children, ...rest }) {
    return <div className="w-screen h-screen flex flex-col items-center bg-passive">
        <div className='h-20 shrink '></div>
        <Logo className="z-20 mb-12 text-3xl"/>
        <Image
            alt="Mountains"
            src="/bg-main.png"
            layout="fill"
            objectPosition={'bottom'}
            objectFit="cover"
            quality={100}
            className={" blur-xs opacity-25"} />
        {children}
    </div>;
}
