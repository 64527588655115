import { useMediaQuery } from 'react-responsive'

export const mobile = rules => `
// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {${rules}}
`

export const useScreenSize = () => {
    const phone = useMediaQuery({
        maxWidth: 767.98
    })

    const smallPhone = useMediaQuery({
        maxWidth: 400
    })
    return {phone, smallPhone}
}