import { collection, doc, getDocs, query, updateDoc, where } from "@firebase/firestore";

import { db } from "..";
import { userConverter } from "./user";

export const getCollectiveRef = (id: string) => doc(db, "users", id).withConverter(userConverter);
export const getUserCollectivesQuery = (uid: string) =>
  query(
    collection(db, `users`),
    where("members", "array-contains", uid),
    where("isCollective", "==", true)
  ).withConverter(userConverter);

export const getUserCollectives = async (userId: string) => {
  const snapshot = await getDocs(getUserCollectivesQuery(userId));
  if (snapshot.docs.length == 0) return null;

  //@ts-ignore
  return snapshot?.docs[0]?.id || null;
};

export const updateCollectiveObject = async (collectiveId: string, data: any) => {
  const collectiveRef = getCollectiveRef(collectiveId);
  await updateDoc(collectiveRef, data);
};
