import styled from "styled-components"

export default styled.button`
    background: ${props => props.disabled ? "#8f8f8f" : props.primary ? "#0094FF" : props.secondary ? "transparent" :props.secondaryStatic ? "#ffffff2b" : props.success ? "#24974e" : props.fail ? "#cb222a" : "white"};;
    border-radius: 6px;
    padding: 0px 1.25rem;
    color: ${props => props.disabled ? "#565656" : props.secondary ? "white" : (props.primary || props.success || props.fail) ? "white" : "black"};
    font-size: 1rem;
    display: block;
    height: 35px;
    display: flex;
    align-items:center;
    justify-content: center;
    white-space: nowrap;
    
    cursor: ${props => (props.success) ? "default" : props.disabled ? "not-allowed" : "pointer"};
    box-sizing: border-box;
    transition: background .2s ease, color .2s ease;

    :hover{
        background: ${props => (!props.success && !props.fail && !props.disabled) && (props.primary ? "#0388e9" : props.secondary ? "#ffffff2b" : props.secondaryStatic ? "#ffffff4a" : "#f3f3f3")};
    }
    :active{
        background: ${props => (!props.success && !props.fail && !props.disabled) && (props.primary ? "#006ebd" : props.secondary ? "#ffffff70" : "#f3f3f3")};
    }
`