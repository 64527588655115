import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from "@firebase/firestore";
import { getUser, User } from ".";

export const userConverter: FirestoreDataConverter<User> = {
  toFirestore(user: WithFieldValue<User>): DocumentData {
    return user;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): User {
    const data = snapshot.data(options);

    // if (data.isCollective) {
    // data.creatorUser = await getUser(data.creator);
    // }
    return { ...data, uid: snapshot.id, id: snapshot.id, ref: snapshot.ref };
  },
};


export const ADMIN_ID = "5eHnOswBniXAHxW2HQgonsUmCdL2"