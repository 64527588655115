// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCznZthOvw12dLIY2jqIuPMB-2VKI2KsMU",
  authDomain: "beatpacks.firebaseapp.com",
  projectId: "beatpacks",
  storageBucket: "beatpacks.appspot.com",
  messagingSenderId: "706909452051",
  appId: "1:706909452051:web:c7e71b93286c6777bc8ce6",
  measurementId: "G-GYBBSBQC0V"
};

export let analytics;
// Initialize Firebase
export const app = initializeApp(firebaseConfig);
if (typeof window !== "undefined") {
  analytics = getAnalytics(app);
}
export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app)
export const functions = getFunctions(undefined, "europe-west3");