import { withRouter } from "next/router"
import styled from "styled-components"

export default styled.input`
    background: ${props => props.transparent ? "#3f3f3f" : "white"};
    border-radius: 6px;
    padding: ${ props => props.readonly ? "6px 12px" : "20px 12px"};
    color: ${props => props.transparent ? "white" : "black"};
    font-size: 14px;
    display: block;
    width: ${props => props.full ? '100%' : '270px'};
    height: ${props => props.readonly ? "auto": "35px"};
    box-sizing: border-box;
    width:100%;

    outline-width: ${props => props.error ? "0.15rem" : "0"};
    outline-style: solid;
    outline-color: ${props => props.error ? "#ff71719c" : "#55b7ffab"};
    ${props => props.allowBorder ? "" : "border: none;"}

    transition: outline .2s ease;
    :focus {
        outline-width: 0.15rem !important;
    }

    ::placeholder{
        color: #9f9f9f;
    }
`

