import styled from "styled-components";
import { mobile } from "./../../styles/media-queries";

export const SectionTitle = styled.h2`
    font-family: Poppins;
    font-weight: normal;
    font-size: 2rem;
    margin-bottom: .2rem;
`;

export const Title = styled.h1`
    font-family: Poppins;
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.2px;
    text-align: center;
    ${mobile(`
        font-size: 2.5rem;
        line-height: 3.5rem;
    `)}
    
`;

export const Subtitle = styled.h3`
    font-family: Poppins;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.2px;
    ${props => !props.left && 'text-align: center;'}
`;

export const Paragraph = styled.p`
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    letter-spacing: 0.2px;
    margin: .6rem 0px;

    color: #9f9f9f;
`;