import * as Sentry from '@sentry/nextjs'
import { getAuth, signInAnonymously } from "firebase/auth"
import { doc, onSnapshot } from "firebase/firestore"
import Link from "next/link"
import { useRouter } from "next/router"
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { useAuthState } from 'react-firebase-hooks/auth'
import Button from "../components/inputs/Button"
import Loader from "../components/misc/Loader"
import { auth, db } from "../firebase"
import { getUserRef } from "../firebase/firestore"
import Login from "../pages/login"
import { useScreenSize } from "../styles/media-queries"
import { CompleteSignup } from "./CompleteSignup"

import { hotjar } from 'react-hotjar';


const headlines = [
    // <div>No more scrolling through old dms to find dropbox links.</div>,
    <div>Email is for emails. Dropbox is for documents. Beatpacks is for music.</div>,
]

function isSharedPack(url) {
    // redirect to login page if accessing a private page and not logged in 
    const path = url.split('?')[0];
    return !!path.match(/^\/@(.*)\/shared?\/(.*)/g)
}

function isPrivatePack(url) {
    // redirect to login page if accessing a private page and not logged in 
    const path = url.split('?')[0];
    return !!path.match(/^\/@(.*)\/(.*)/g) || url === "/[nickname]/[privateLink]"
}

function isPublicPath(url) {
    const publicPaths = ['/login', '/landing', '/', '/join', '/privacy', '/terms'];
    const path = url.split('?')[0];
    return publicPaths.includes(path)
}
function SignUpFooter({ ...rest }) {
    const { phone } = useScreenSize()
    const headline = useMemo(() => headlines[Math.floor(Math.random() * headlines.length)], [])
    return <div className={` text-white fixed p-4 bottom-0 left-0 z-30 bg-black shadow-md w-screen`}>
        <div className={`flex flex-col md:flex-row h-14 justify-between items-center w-full m-auto max-w-5xl`}>
            <div className="w-full">
                {headline}
                <div className="text-sm">
                    Easily access all your packs in one place.
                </div>
            </div>

            <div className={`relative m-2 md:m-0 ${phone ? 'w-full' : ""} md:w-auto`}>
                <Link href={`/`}><Button className="m-4 md:m-0 px-8 w-full md:w-auto  whitespace-nowrap">Sign up</Button></Link>
                {/* <div style={{ marginTop: 2 }} className="absolute top-full mt-1 opacity-70 text-center w-full left-0whitespace-nowrap text-xxs text-light-gray">It's free</div> */}
            </div>
        </div>
    </div>
}

export default function useFirebaseAuth() {
    const [user, loading, error] = useAuthState(getAuth());

    const router = useRouter()
    const pagePublic = isSharedPack(router.asPath)

    const [fsLoading, setFsLoading] = useState(false)
    const signupCompleted = useCallback((user) => {
        if (!user) return null

        return !!user?.nickname
    }, [])

    const [fsUser, setFsUser] = useState(null)
    const [settings, setSettings] = useState(null)
    const [roLoading, setRoLoading] = useState(null)
    const [settingsLoading, setSettingsLoading] = useState(null)
    const [weekliesLoading, setWeekliesLoading] = useState(null)
    const unsubFsUser = useRef(null)
    const unsubUserSettings = useRef(null)
    useEffect(() => {
        //@ts-ignore
        if (user) { window.heap.identify(user.uid); hotjar.identify(user.uid) }
        if (!user && unsubFsUser.current) { setFsUser(null); unsubFsUser.current(); unsubFsUser.current = null; return }
        if (!user && !loading && pagePublic) {
            setFsLoading(true)
            // create anonymous user
            signInAnonymously(auth).then(console.log)
        }
        if (unsubFsUser.current !== null) return
        (async () => {

            if (!user) return
            setFsLoading(true)
            setRoLoading(true)
            setSettingsLoading(true)
            setWeekliesLoading(true)
            console.log(await user.getIdTokenResult())
            let unsubUserDoc = onSnapshot(await getUserRef(user.uid), (newUserDoc) => {
                setFsUser(oldFsUser => ({ ...user, ...oldFsUser, ...newUserDoc.data(), signupCompleted: signupCompleted(newUserDoc.data()), id: user.uid }))
                setFsLoading(false)
            })

            let unsubRoDoc = onSnapshot(doc(db, `users/${user.uid}/private/ro`), (newRoDoc) => {
                setFsUser(oldFsUser => {
                    if (newRoDoc?.data() && oldFsUser && oldFsUser?.occupied !== newRoDoc?.data()?.occupied)
                        user.getIdToken(true)

                    return ({ ...user, ...oldFsUser, ...newRoDoc.data(), plan: newRoDoc.data()?.plan || oldFsUser?.plan || "free", occupied: newRoDoc?.data()?.occupied || 0 })
                })
                setRoLoading(false)
            })

            let unsubUserSettings = onSnapshot(doc(db, `users/${user.uid}/private/settings`), (newSettingsDoc) => {
                setFsUser(oldFsUser => {
                    return ({ ...user, ...oldFsUser, settings: newSettingsDoc.data() })
                })
                setSettingsLoading(false)
            })

            let unsubUserWeeklies = onSnapshot(doc(db, `users/${user.uid}/private/weeklies`), (newWeekliesDoc) => {
                setFsUser(oldFsUser => {
                    return ({ ...user, ...oldFsUser, weeklies: newWeekliesDoc.data() })
                })
                setWeekliesLoading(false)
            })


            unsubFsUser.current = () => { unsubUserDoc(); unsubRoDoc(); unsubUserSettings(); unsubUserWeeklies(); }
        })()
    }, [user, loading])
    

    const somethingLoading = loading || fsLoading || roLoading || settingsLoading || weekliesLoading
    useEffect(() =>{
        if (somethingLoading || !fsUser) return 
        Sentry.setUser(fsUser);
    
        hotjar.identify(fsUser.uid, fsUser)
    }, [somethingLoading, fsUser])

    return {
        user: fsUser,
        loading: somethingLoading,
    }
}

const authUserContext = createContext({
    user: null,
    loading: true
})
export function AuthUserProvider({ children }) {
    const auth = useFirebaseAuth();

    const loader = useMemo(() => <Loader />, [])
    const router = useRouter()
    const pagePublic = isPublicPath(router.asPath)

    const signedUp = auth?.user && !auth.user?.isAnonymous

    const accessibleCausePublic = pagePublic && auth.user?.isAnonymous


    const publiclyAccessiblePage = pagePublic || isSharedPack(router.asPath)
    const privatePack = isPrivatePack(router.asPath)
    const needToComplete = signedUp && !auth.user?.signupCompleted
    const showContent = (!auth.loading && ((signedUp && auth.user?.signupCompleted) || publiclyAccessiblePage))

    return <authUserContext.Provider value={auth}>
        {auth.loading && loader}
        {!auth.loading && !showContent && !needToComplete && <Login title={privatePack && 'Sign in to see this pack'} />}
        {!publiclyAccessiblePage && !auth.loading && needToComplete && <CompleteSignup />}
        {showContent && !(needToComplete && !publiclyAccessiblePage) && children}
        {!auth.loading && !signedUp && isSharedPack(router.asPath) && <SignUpFooter />}
    </authUserContext.Provider>;
}

export const useAuth = () => useContext(authUserContext)