import { signOut } from 'firebase/auth'
import Link from 'next/link'
import React from 'react'
import { MdLogout, MdSettings } from 'react-icons/md'
import ReactTooltip from 'react-tooltip'
import { auth } from '../../firebase'
import { MAX_UPLOADS } from '../../firebase/firestore'
import { mergeSorted } from '../../utils/arrays'
import { capitalizeFirstLetter } from '../../utils/string'

export function formatMb(mb: number, forceInGb?: boolean) {
    if (mb < 1000 && !forceInGb) return `${mb} MB`
    return `${(mb / 1000).toFixed(1).replace('.0', '')} GB`
}

function ProgressBar({ filled, leftLabel, rightLabel, circleLabel, className = "", ...rest }) {

    return <div>
        <div className={className + ` rounded-full relative h-1 w-full bg-white bg-opacity-20`} {...rest}>
            <ReactTooltip delayShow={600} place='bottom' effect='solid' />

            <div style={{ width: `${filled > 1.0 ? 100 : filled * 100}%` }} className={`duration-500 transition-all ease-in-out slow h-1 bg-white rounded-full`} />
            {/* {<div style={{ left: `${filled * 100}%` }} className={`duration-500 transition-all ease-in-out top-1/2 ${filled === 0 || filled === 1 ? 'w-0 h-0' : 'w-2 h-2'}  bg-blue-accent rounded-full absolute -translate-x-1/2 -translate-y-1/2`}></div>} */}
            {<div style={{ left: `${filled > .95 ? 95 : filled < 0.02 ? 2 : filled * 100}%` }} className={`duration-500 whitespace-nowrap transition-all ease-in-out top-full uppercase flex justify-between mt-2.5 text-xxs text-white absolute -translate-x-1/2 -translate-y-1/2`}>{circleLabel}</div>}
        </div>

        <div className='relative uppercase flex justify-end mt-1 text-xxs opacity-50'>
            <div className={`${filled > 0.7 && 'opacity-0'}`}>{rightLabel}</div>
        </div>
    </div>
}

export default function UserWidget({ user, card = true, className, ...rest }) {

    const storageInGB = MAX_UPLOADS[user?.plan || "free"] > 1000
    return (<div className={(className || "") + ` pb-3 p-4  ${card && 'bg-white bg-opacity-10 rounded-md '}`}>
        <div className={` flex  items-center `}>
            {user.photoURL && <img referrerPolicy='no-referrer' src={user.photoURL} className={` w-7 rounded-full mr-2`} />}
            <div>
                <div className='text-sm'>{user.nickname && `@${user.nickname}` || user.displayName || user.email}</div>
                <div className='hover:underline cursor-pointer opacity-70 text-xs'>
                    <Link href={`/settings`}><span>
                        {(user?.plan && capitalizeFirstLetter(user?.plan)) || "Free"} plan
                    </span>
                    </Link></div>
            </div>
            <div className='flex items-center ml-auto'>
                <Link href={'/settings'}><span><MdSettings className='ml-auto mr-2 opacity-60 cursor-pointer hover:opacity-100 transition ' /></span></Link>
                <MdLogout className='opacity-60 cursor-pointer hover:opacity-100 transition ' onClick={() => signOut(auth)} />
            </div>

        </div>
        <ProgressBar className='mt-2' circleLabel={formatMb(user.occupied, storageInGB)} filled={user.occupied / MAX_UPLOADS[user?.plan || "free"]} leftLabel={`0 ${storageInGB ? "GB" : "MB"}`} rightLabel={formatMb(MAX_UPLOADS[user?.plan || "free"])} />
    </div>
    )
}
