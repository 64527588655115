import { createContext, useCallback, useContext, useEffect, useState } from "react"
import { useAudioPlayer, useAudioPosition } from "react-use-audio-player"
import { getStemAudioUrl, getTrackAudioURL, Track } from "../firebase/firestore"


export type TrackPlayerValue = {
    playTrack: (track: Track) => Promise<any>;
    currentTrack: Track;
    play: () => any;
    pause: () => any;
    goToPosition: (pos: number) => any;
    [x: string | number | symbol]: unknown;
}
function _useTrackPlayer(): TrackPlayerValue {
    const _audioPlayer = useAudioPlayer()
    const { togglePlayPause, ready, play, pause, loading, load, playing, player } = _audioPlayer;
    const [currentTrack, setCurrentTrack] = useState(null)

    const { percentComplete, duration, seek } = useAudioPosition({ highRefreshRate: true })

    // console.log(percentComplete)
    const goToPosition = useCallback(async (percentage) => {
        seek(duration * percentage)
    }, [duration, seek])

    // useEffect(play, [play])

    const loadTrack = async (track: Track) => new Promise(async res => {
        console.log(`loading ${track.title}`)
        pause()
        load({
            src: await getTrackAudioURL(track),
            format: ["mp3", "wav", "aac"],
            html5: true,
            onend: () => console.log("sound has ended!"),
            autoplay: true,
            onload: res,
            onplay: () => setCurrentTrack(track)
        })
    })

    const loadTrackWithStems = async (track: Track) => Promise.all(
        track.stems.map((stem, idx) =>
            new Promise(async res => {
                console.log(`loading ${stem.name}`)
                pause()
                load({
                    src: await getStemAudioUrl(stem),
                    format: ["mp3", "wav", "aac"],
                    html5: true,
                    onend: () => console.log("sound has ended!"),
                    autoplay: false,
                    onload: () => { console.log(`${stem.name} loaded`); res([]) },
                    onplay: (idx == 0 && (() => setCurrentTrack(track)))
                })
            })
        )
    ).then(() => { console.log('playing'); play() })
    const playTrack = async (track: Track) => {
        pause()
        // console.log(`playing ${currentTrack?.title} but requested to play ${track.title}`)
        if (currentTrack?.id !== track.id) {
            // if (track?.stems?.length) await loadTrackWithStems(track)
            setCurrentTrack(track)
            await loadTrack(track)
        }
        else play()
        // console.log(`playing ${track.title}`)
    }

    return { playTrack, currentTrack, play, pause, loading, playing, goToPosition, percentComplete, duration, ..._audioPlayer }
}


const TrackPlayerContext = createContext({ playTrack: null, currentTrack: null, play: null, pause: null, goToPosition: null });

export function TrackPlayerProvider({ children }) {
    const trackPlayer = _useTrackPlayer();
    return <TrackPlayerContext.Provider value={trackPlayer}>
        {children}
    </TrackPlayerContext.Provider>;
}

export const useTrackPlayer = (): TrackPlayerValue => useContext(TrackPlayerContext)