import styled from "styled-components"

// todo: style
export default styled.span`
    // font-family: Open Sans, sans-serif;
    cursor: pointer;

    :hover {
        text-decoration: underline;
    }
`