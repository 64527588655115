import React from "react";
import { useAnimatePresence } from "use-animate-presence";

const variants = {
  opacity: { from: 0, to: 100 },
};

export default function Loader() {
  const animatedDiv = useAnimatePresence({
    variants,
    initial: "visible",
    duration: 200,
  });
  return (
    animatedDiv.isRendered && (
      <div ref={animatedDiv.ref} className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transition-all">
        <div className="loader">Loading...</div>
      </div>
    )
  );
}
