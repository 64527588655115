import Image from 'next/image';
import { useRouter } from 'next/router';
import Modal from 'react-modal';
import { useAuth } from '../../hooks/useAuth';
import Navbar from './Navbar';

// import Feedback from "feeder-react-feedback"; // import Feedback component
import "feeder-react-feedback/dist/feeder-react-feedback.css"; // import stylesheet
import dynamic from 'next/dynamic';

const Feedback = dynamic(() =>
    import('feeder-react-feedback').then((mod) => mod), { ssr: false }
)
Modal.setAppElement("#__next")
export default function Layout({ children }) {
    const router = useRouter()
    const { user } = useAuth()
    return (
        <div className={"flex flex-col md:flex-row w-screen h-screen bg-passive text-textcolor"}>

            <div className='bgimg'>
                <Image
                    alt="Mountains"
                    src="/bg-main.png"
                    layout="fill"
                    objectPosition={'bottom'}
                    objectFit="cover"
                    quality={100}
                    className={" blur-xs opacity-25"} />
            </div>
            


            {user && !user.isAnonymous && <Navbar className="shrink-0 md:static md:w-3/12 z-20" />}
            {/* <LazyMotion features={domAnimation}>
                <AnimatePresence exitBeforeEnter>
                    <motion.div
                        key={router.route.concat('ani')}
                        className="page-wrap"
                        initial={{ opacity: 0, scale: 0.95 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.95 }}
                        transition={{ duration: 0.5 }}
                    > */}
            <div className='grow overflow-auto relative' id="main" >
                <main suppressHydrationWarning={true}  className='bg-main pb-24 md:pb-0 grow'>
                    {children}
                    <Feedback projectId="6240438bd4f0260004586fa9" primaryColor="black" className="text-black" />
                </main>

                {/* <Footer className='grow-0' /> */}
            </div>
            {/* </motion.div>
                </AnimatePresence>
            </LazyMotion> */}
        </div >
    )
}